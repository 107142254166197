import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import theme from "../../../theme";
import { format } from "../../../utils/date";
import mq from "../../../utils/mediaQuery";
import Image, { ImageType } from "../../ImageComponent";
import ReplaceableLink from "../../ReplaceableLink";
import Tag from "../../Tag";
import Text from "../../Text";
import { bodySmall, ctaSmall } from "../../Typography";

const ImageWrapper = styled.div`
  overflow: hidden;
  > div {
    transition: all 350ms cubic-bezier(0.72, 0, 0.28, 1);
  }
`;

const ArticleMeta = styled.div`
  margin-top: ${theme.space("s")};
  ${mq("2")} {
    margin-top: ${theme.space("m")};
  }
`;

const StyledDate = styled(Tag)`
  margin-left: ${theme.space("xxs")};
`;

const ArticleTitle = styled(Text)`
  ${bodySmall};
  margin: 4px 0;

  ${mq("2")} {
    margin-bottom: 1em;
  }
`;

const ArticleLink = styled(Text)`
  ${ctaSmall};
  margin-top: 4px;
  color: ${theme.color("primary")};
  transition: color 350ms cubic-bezier(0.72, 0, 0.28, 1);

  ${mq("2")} {
    margin-top: ${theme.space("xxs")};
  }
`;

const StyledArticle = styled(ReplaceableLink)`
  &.highlight {
    padding-bottom: ${theme.space("m")};
    grid-row: auto / span 4;
  }

  &.regular {
    grid-row: auto / span 1;
    border-top: 1px solid black;

    &:last-of-type {
      padding-bottom: ${theme.space("xs")};
      border-bottom: 1px solid black;
    }

    ${mq("3")} {
      margin-left: ${theme.space("s")};
    }
  }

  &:hover {
    ${ArticleLink} {
      color: ${theme.color("secondary")};
    }

    ${ImageWrapper} {
      > div {
        transform: scale(1.05);
      }
    }
  }
`;

function ArticlesItem({
  index,
  highlighted = false,
  type,
  date,
  title,
  url,
  thumbnail,
  as,
  ...props
}) {
  const [imageSize, setImageSize] = useState("highlight");

  // TODO: replace with a useMq hook (https://app.shortcut.com/greenberry/story/19998/toevoegen-van-een-mediaquery-hook)
  useEffect(() => {
    const setValues = () => {
      const imageSize =
        window.innerWidth < parseInt(theme.bp("2").slice(0, -2))
          ? "small"
          : "highlight";
      setImageSize(imageSize);
    };

    setValues();
    document.addEventListener("load", setValues);
    window.addEventListener("resize", setValues);

    return () => {
      document.removeEventListener("load", setValues);
      window.removeEventListener("resize", setValues);
    };
  }, []);

  return (
    <StyledArticle
      url={url}
      as={as}
      index={index}
      className={highlighted ? "highlight" : "regular"}
      {...props}
    >
      {!!highlighted && (
        <ImageWrapper>
          <Image image={{ data: thumbnail[imageSize] }} />
        </ImageWrapper>
      )}
      <ArticleMeta>
        <Tag color={"dark.light"}>{type}</Tag>
        {date && (
          <StyledDate color={"dark.light"} weight={"regular"}>
            {format(new Date(date))}
          </StyledDate>
        )}
        <ArticleTitle>{title}</ArticleTitle>
        <ArticleLink>Lees meer</ArticleLink>
      </ArticleMeta>
    </StyledArticle>
  );
}

export const articlesItem = {
  type: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.shape(ImageType),
  as: PropTypes.elementType,
  highlighted: PropTypes.bool,
};

ArticlesItem.propTypes = {
  ...articlesItem,
};

export default ArticlesItem;
