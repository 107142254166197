import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const VisuallyHiddenContainer = styled.span`
  display: inline-block;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: none;
`;

const VisuallyHidden = ({ children, ...props }) => {
  const [forceShow, setForceShow] = useState(false);
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      const handleKeyDown = (ev) => {
        ev.key === "Alt" && setForceShow(true);
      };
      const handleKeyUp = (ev) => {
        ev.key === "Alt" && setForceShow(false);
      };

      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keydown", handleKeyUp);
      };
    }
  }, []);

  return forceShow ? (
    children
  ) : (
    <VisuallyHiddenContainer {...props}>{children}</VisuallyHiddenContainer>
  );
};

VisuallyHidden.propTypes = {
  children: PropTypes.node,
};

export default VisuallyHidden;
