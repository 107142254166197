import styled from "@emotion/styled";
import { node, oneOf } from "prop-types";
import React from "react";

import theme from "../../theme";
import { caption } from "../Typography";

const Wrapper = styled.span`
  ${caption};
  font-weight: ${({ weight }) =>
    theme.get(`type.fontWeight.primary.${weight}`)};
  color: ${({ textColor }) =>
    theme.color(textColor.includes(".") ? textColor : `${textColor}.base`)};
`;

function Tag({ children, color, weight, ...props }) {
  return (
    <Wrapper weight={weight} textColor={color} {...props}>
      {children}
    </Wrapper>
  );
}

Tag.propTypes = {
  children: node.isRequired,
  weight: oneOf(["regular", "bold", "black"]),
  color: oneOf(["primary", "secondary", "blue", "gray", "text", "dark.light"]),
};

Tag.defaultProps = {
  weight: "bold",
  color: "blue",
};

export default Tag;
