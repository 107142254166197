import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { map, prop } from "ramda";
import React from "react";

import FilteredArticles from "../components/FilteredArticles";
import Hero from "../components/Hero/v8";
import SEO from "../components/SEO";
import { UPDATES_PATH } from "../constants";
import { updateItem, updatePage, updateType } from "../types";
import createLink from "../utils/createLink";

const filterMap = (allUpdateTypes) =>
  map(
    ({ id, name }) => ({
      id,
      label: name,
    }),
    allUpdateTypes
  );

const updatesMap = (allUpdates) =>
  map(
    ({
      id,
      updateType,
      highlighted,
      title,
      thumbnail,
      tagline,
      slug,
      date,
    }) => ({
      id,
      date,
      type: updateType.name,
      title,
      body: tagline,
      thumbnail,
      highlighted: highlighted,
      as: createLink(`${UPDATES_PATH}/${slug}`),
      filters: filterMap([updateType]),
    }),
    allUpdates
  );

const Updates = ({
  data: {
    data: { updatePage, allUpdates, allUpdateTypes },
  },
}) => {
  const filters = filterMap(allUpdateTypes);

  return (
    <>
      <SEO
        tags={[
          {
            tag: "title",
            content: "Updates | Greenberry",
          },
        ]}
      />

      <Hero
        title={prop("title", updatePage)}
        introduction={prop("description", updatePage)}
      />

      <FilteredArticles
        articles={updatesMap(allUpdates)}
        filters={filters}
        itemAmount={6}
        itemIncrement={9}
        highlights
        noItemsText="Geen updates gevonden."
      />
    </>
  );
};

Updates.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      updatePage: PropTypes.shape(updatePage).isRequired,
      allUpdates: PropTypes.arrayOf(PropTypes.shape(updateItem)).isRequired,
      allUpdateTypes: PropTypes.arrayOf(PropTypes.shape(updateType)).isRequired,
    }),
  }),
};

export default Updates;

export const query = graphql`
  query UpdatePageQuery {
    data: dato {
      updatePage {
        title
        description
      }
      allUpdates(orderBy: date_DESC, first: "100") {
        id
        title
        description
        tagline
        date
        slug
        highlighted
        updateType {
          name
          id
        }
        thumbnail {
          highlight: responsiveImage(
            imgixParams: { fit: crop, w: 412, h: 447, auto: format }
          ) {
            ...responsiveImageFragment
          }
          small: responsiveImage(
            imgixParams: { fit: crop, w: 400, h: 300, auto: format }
          ) {
            ...responsiveImageFragment
          }
        }
      }
      allUpdateTypes {
        id
        name
      }
    }
  }
`;
