import styled from "@emotion/styled";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import useItemFilter, { configType } from "../../utils/itemFilter";
import mq from "../../utils/mediaQuery";
import ArticlesItem, { articlesItem } from "../Articles/v2/ArticlesItem";
import ContentWrapper from "../ContentWrapper";
import { filterItem } from "../Filter";
import Heading from "../Heading";
import VisuallyHidden from "../VisuallyHidden";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${theme.space("m")};

  ${mq("2")} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ArticleWrapper = styled.div`
  ${mq("2")} {
    margin-bottom: ${theme.space("xl")};

    &:nth-of-type(3n + 2) {
      transform: translateY(60px);
    }

    &:nth-of-type(3n + 3) {
      transform: translateY(120px);
    }
  }
`;

function FilteredArticles({
  articles,
  filters,
  activeFilter,
  highlights,
  itemAmount,
  itemIncrement,
  moreButtonText,
  filterAllLabel,
  noItemsText,
  onChange,
  ...props
}) {
  const itemFilter = useItemFilter(
    articles,
    filters,
    activeFilter,
    onChange,
    highlights,
    {
      itemAmount,
      itemIncrement,
      moreButtonText,
      filterAllLabel,
      noItemsText,
    }
  );

  const listAnimation = {
    open: theme.get("animation.staggeredReveal"),
  };

  const itemAnimation = {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: 60,
      opacity: 0,
    },
  };
  const isSSR = typeof window === "undefined";

  return (
    <div {...props}>
      <VisuallyHidden>
        <Heading size={2}>Alle updates</Heading>
      </VisuallyHidden>
      <ContentWrapper columns={12}>{itemFilter.filterElement}</ContentWrapper>

      <motion.div
        variants={listAnimation}
        initial={isSSR ? "open" : "closed"}
        animate="open"
        static={process.env.NODE_ENV === "test"}
      >
        <ContentWrapper columns={12}>
          <GridWrapper>
            {itemFilter.highlighted.map(({ id, ...item }) => (
              <ArticleWrapper key={id}>
                <motion.div variants={itemAnimation}>
                  <ArticlesItem {...item} />
                </motion.div>
              </ArticleWrapper>
            ))}
            {itemFilter.items.map(({ id, ...item }) => (
              <ArticleWrapper key={id}>
                <motion.div variants={itemAnimation}>
                  <ArticlesItem {...item} highlighted={true} />
                </motion.div>
              </ArticleWrapper>
            ))}
          </GridWrapper>
        </ContentWrapper>
      </motion.div>
      {itemFilter.moreElement}
    </div>
  );
}

FilteredArticles.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape(filterItem)),
  activeFilter: PropTypes.shape(filterItem),
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      ...articlesItem,
    })
  ),
  highlights: PropTypes.bool,
  ...configType,
};

FilteredArticles.defaultProps = {
  articles: [],
  highlights: true,
};

export default FilteredArticles;
